<template>
    <div class="wrap">
        <LoadingProgress v-show="loading && !switching" :loadingValue="100"/>
        <DialogFrame id='dialog-logout-frame' ref='dialogLogout' :needClose='true' @emitConfirm='confirmLogout'>
            <div class="dialog-logout" slot='dialog-content'>
                <h6>Logout</h6>
                <div class="span-box">
                    <span>Come back soon!</span>
                    <span>Are you sure you want ot Log out? </span>
                </div>
            </div>
        </DialogFrame>
        <canvas id="main-scene" @click.prevent></canvas>
        <img class='expo' src="@/assets/img/icon/expo-logo.svg" alt="">
        <div class="animate-theme-reminder" :class="{active: themeAnimator}">
            <img src="@/assets/img/icon/location-blue.svg" alt="">
            <span>{{themeAnimatorText}}</span>
        </div>
        <transition name="el-fade-in-linear">
            <div class="mask" v-show="extendMenu" @click="extendMenu=false"></div>
        </transition>
        <div class="lobby-bar menu-bar">
            <div class="name-box pointer" @click="switchLobbyState(LOBBY_STATE.THEME)">
                <span>{{currentLocationName}}</span>
                <img src="@/assets/img/icon/location.svg" alt="">
            </div>
            <div class="icon-box pointer" @click='extendMenu=!extendMenu;'>
                <img src='@/assets/img/icon/menu.svg' alt="">
                <span>More</span>
            </div>
            <!-- extended menu slots start -->
            <div class="extended-menu" v-show="extendMenu" :class="{hasLogin: login}">
                <div class="icon-box pointer floater" @click='shotPhoto'>
                    <span>Take Photo</span>
                    <img src='@/assets/img/icon/photo.png' alt="">
                    <img src='@/assets/img/icon/photo_active.png' alt="">
                </div>
                <div class="icon-box pointer floater"  v-if="accountType=='seller'" @click="$router.push('/vendor')">
                    <span>My blocks</span>
                    <img src='@/assets/img/icon/block.png' alt="">
                    <img src='@/assets/img/icon/block_active.png' alt="">
                </div>
                <div class="icon-box pointer floater" @click='switchLobbyState(LOBBY_STATE.ACCOUNT)' v-show="login&&accountType!='visitor'" >
                    <span>Account info</span>
                    <img src='@/assets/img/icon/acc-info.png' alt="">
                    <img src='@/assets/img/icon/acc-info_active.png' alt="">
                </div>
                <div class="icon-box pointer floater" @click='switchLobbyState(LOBBY_STATE.CHAT)'>
                    <span>Chat</span>
                    <img src='@/assets/img/icon/acc-info.png' alt="">
                    <img src='@/assets/img/icon/acc-info_active.png' alt="">
                </div>
                <div class="icon-box pointer floater" @click='logoutDialog'>
                    <span>{{login?'Logout':'Login'}}</span>
                    <img :src="login?require('@/assets/img/icon/logout.png'):require('@/assets/img/icon/login.svg')" alt="">
                    <img src="@/assets/img/icon/logout_active.png" alt="">
                </div>
            </div>
            <!-- extended menu slots end -->
            <transition name="el-fade-in-linear">
                <div class="lobby-menu" v-show="lobbyMenuToggle&&!extendMenu">
                    <div class="title">{{lobbyTitle}}</div>
                    <!--  -->
                    <div class="theme-item" v-show="lobbyState===LOBBY_STATE.THEME">
                        <div class="theme-slots" v-for="item,index in themeData" :key="item.td" @click="switchTheme(index)">
                            <div class="index">{{index+1}}</div>
                            <div class="theme-list">
                                <div class="name">{{item.name}}</div>
                                <div class="tags">{{item.description}}</div>
                                <img class='locate' v-show='index+1===currentTheme' src="@/assets/img/icon/location-blue.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="block-item" v-show="lobbyState===LOBBY_STATE.BLOCK" v-if="attachedExhibition">
                        <div class="block-upper">
                            <img v-if="attachedExhibition.coverUrl" :src="attachedExhibition.coverUrl" alt="">
                            <div class="details">
                                <div class="name">{{attachedExhibition.title}}</div>
                                <div class="theme"><img src="@/assets/img/icon/loc.svg" alt="">Theme {{1}} - Auqatics</div>
                                <div v-if="attachedExhibition.sellerProfile.email"><img src="@/assets/img/icon/email.svg" alt="">{{attachedExhibition.sellerProfile.email}}</div>
                                <div v-if="attachedExhibition.sellerProfile.phone"><img src="@/assets/img/icon/call.svg" alt="">{{attachedExhibition.sellerProfile.phone}}</div>
                            </div>
                        </div>
                        <div class="block-lower">{{attachedExhibition.introduction}}</div>
                        <button class="btn pointer" @click='enterExhibition()'>Enter Block
                            <img src="@/assets/img/icon/arr.svg" alt="">
                        </button>
                    </div>
                    <!--  -->
                    <div class="login-item" v-show="lobbyState===LOBBY_STATE.LOGIN">
                        <div>Please login to experience our amazing features!</div>
                        <div class="input-box">
                            <span>Account or email</span>
                            <input type="text" name="account" v-model="userForm.account" placeholder="name@example.com" required>
                        </div>
                        <div class="input-box">
                            <span>Password</span>
                            <input type="password" name='password' v-model="userForm.password" placeholder="Enter your password" required>
                        </div>
                        <div class="btn-block">
                            <button @click="switchLobbyState(LOBBY_STATE.SIGNUP)">Signup</button>
                            <button @click='loginAccount()'>Login</button>
                        </div>
                    </div>
                    <div class="signup-item" v-show="lobbyState===LOBBY_STATE.SIGNUP">
                        <div class="input-box">
                            <span>Your Name</span>
                            <input type="text" name="name" v-model="userForm.signupName" placeholder="Enter user name" required>
                        </div>
                        <div class="input-box">
                            <span>Account</span>
                            <input type="text" name="name" v-model="userForm.signupAccount" required placeholder="Enter account">
                        </div>
                        <div class="input-box">
                            <span>Email</span>
                            <input type="email" name="email" v-model="userForm.signupEmail" required placeholder="name@example.com">
                        </div>
                        <div class="input-box">
                            <span>Password</span>
                            <input type="password" name='signupPassword' v-model="userForm.signupPassword" required placeholder="At least 8 characters">
                        </div>
                        <div class="input-box">
                            <span>Confirm Password</span>
                            <input type="password" name='signupConfirmPassword' v-model="userForm.signupConfirmPassword" required placeholder='Re-enter password'>
                        </div>
                        <div class="btn-block">
                            <button>Already have an account</button>
                            <button>Continue</button>
                        </div>
                    </div>
                    <!--  -->
                    <div class="chat-item" v-show="lobbyState===LOBBY_STATE.CHAT">
                        <div class="chat-tags">
                            <div class="chats pointer" :class="{active:channel=='system'}" @click="channel='system'">System</div>
                            <div class="chats pointer" :class="{active:channel=='chat'}" @click="channel='chat'">Chat</div>
                        </div>
                        <div class="chat-box" v-show="channel=='chat'">
                            <div v-show="chatroomData.length" class="chat-container" v-for="item in chatroomData" :key="item.index" :class="{self:item.name===nickname}">
                                <div class="name">{{item.name}}</div>
                                <div class="messages">{{item.messages}}</div>
                                <div class="date">{{item.date}}</div>
                            </div>
                        </div>
                        <div class="chat-box" v-show="channel=='system'">
                            <div v-show="systemData.length" class="chat-container" v-for="item in systemData" :key="item.index">
                                <div class="name">{{item.name}}</div>
                                <div class="messages">{{item.messages}}</div>
                                <div class="date">{{item.date}}</div>
                            </div>
                        </div>
                        <div class="send">
                            <input type="text" name='chatMessage' v-model="chatMessage" placeholder="Say something..." autocomplete="off" v-on:keydown='keydownEvent'>
                            <img class='pointer' src="@/assets/img/icon/send.svg" alt="" @click='sendChatMessage'>
                        </div>
                    </div>
                    <!--  -->
                    <div class="accountinfo-item" v-show="lobbyState===LOBBY_STATE.ACCOUNT">
                        <div class="input-box">
                            <span>Account ID</span>
                            <input type="text" name="account" v-model="accountInform.account" placeholder="Enter your account" :disabled="true" required>
                        </div>
                        <div class="input-box">
                            <span>User Name</span>
                            <input type="text" name="account" v-model="accountInform.nickname" placeholder="Enter your account" :disabled="true" required>
                        </div><div class="input-box">
                            <span>Email</span>
                            <input type="text" name="account" v-model="accountInform.email" placeholder="Enter your email" :disabled="true" required>
                        </div>
                        <!-- <div class="input-box">
                            <span>Password</span>
                            <input type="password" name='password' v-model="accountInform.password" placeholder="Enter your password" :disabled="" required>
                        </div> -->
                        <div class="btn-block" v-show="accountType!='visitor'&&accountType!='seller'">
                            <button @click='goLanding'>Become a blocker</button>
                        </div>
                    </div>
                    <img src="@/assets/img/icon/close.svg" alt="" class="close pointer" @click='lobbyMenuToggle=false'>
                </div>
            </transition>
        </div>
        <FirstTimeTips v-if="tips" @closeTip="closeTip"/>
        <ScreenShot :screenShotSrc="screenShotSrc" v-if="screenShotSrc!=''" @close="closeScreenShot"/>
        <Keyboard :keyInputs="keyInputs"  @emitKeepMoveCamera="emitKeepMoveCamera"
            @emitResetKeyboard="emitResetKeyboard"/>
        <janus/>
    </div>
</template>
<script>
import * as Babylon from "@babylonjs/core";
import "@babylonjs/loaders";
import * as np from "nipplejs";
import LoadingProgress from '@/components/LoadingProgress';
import DialogFrame from "@/components/DialogFrame.vue";
import Keyboard from "@/components/Keyboard.vue";
import janus from "@/components/janus.vue";
import FirstTimeTips from "@/components/FirstTimeTips.vue";
import ScreenShot from "@/components/ScreenShot.vue";
import { mapGetters, mapActions } from 'vuex';
import {getExhibitions,logout,getExhibitionsFacets,userSignup,login,getUserData,getVenderProfile} from "@/api";

const MAX_SHOP_NUM = 5;
const DEFAULT_THEME_DATA = [];
const DEFAUL_CHATROOM_DATA = [];

//CHATROOM
const SEND_MESSAGE = 'chatroom new message';

//MULTIPLAYERS
const PLAYER_INIT = 'expoblocks user init';
const PLAYER_JOIN = 'expoblocks user join';
const PLAYER_LEFT = 'expoblocks user left';
const PLAYER_MOVE = 'expoblocks user moving';
const PLAYER_DISCONNECT = 'disconnect';

let previousCall = 0;

let scene, engine, canvas, camera;
let resizer = null;

export default {
    data(){
        return{
            // scene: null,
            // engine: null,
            camera: null,
            // canvas: null,
            shopArr: null,
            shopSize: 5,
            shopClone: [],
            // shopName: ['exhLayout','exhLayout','exhLayout','exhLayout','exhLayout','exhLayout'],
            showExhibInfo: false,
            currentLocationName: 'All Exhibitions',
            currentRegion: 1,
            currentTheme: 1,
            authType: localStorage.getItem('token'),
            accountType: localStorage.getItem('type'),
            // login: false,
            firstEnter: false,
            loading: false,
            switching: false,
            shopsLoaded: 0,
            shopsCloned: 0,
            lastFunctionUI: function(){},
            keyInputs: [],
            move: {x:0,y:0},
            floors:'',
            tags:'',
            //Api
            exhibitionsData:[],
            exhibitionsPage:{},
            attachedExhibition: {
                title: '',
                coverUrl: '',
                sellerProfile:{
                    phone:'',
                    email:'',
                },
                introduction:''
            },
            themeData: DEFAULT_THEME_DATA,
            //
            character: null,
            lobbyState: 3,
            lobbyMenuToggle: false,
            loginMenuToggle: false,
            themeAnimator: false,
            themeAnimatorText: 'themeAnimatorText',
            extendMenu: false,
            LOBBY_STATE: Object.freeze({
                "LOGIN": 1,
                "SIGNUP": 2,
                "THEME": 3,
                "BLOCK": 4,
                "CHAT": 5,
                "ACCOUNT": 6
            }),
            userForm:{
                acoount:'',
                password:'',
                signupAccount:'',
                signupEmail:'',
                signupPassword:'',
                signupConfirmPassword:'',
                signupName:'',
            },
            chatMessage: '',
            characterDestination: {
                distance:0,
                target:null,
            },
            socket: this.$store.state.socket,
            chatroomData: DEFAUL_CHATROOM_DATA,
            nickname: '黑創小夥伴'+Math.floor(Math.random()*99999),
            playerData: [],
            lastCharacterData: [],
            accountInform:{
                account:"",
                nickname:"",
                email:"",
                password:""
            },
            tips:false,
            channel:'chat',
            systemData:[],
            screenShotSrc:"",
            prevExhibitionData:[],
            invertRotation: false,
        }
    },
    components:{
        LoadingProgress, DialogFrame,FirstTimeTips,ScreenShot, Keyboard, janus
    },
    created(){
        this.$store.dispatch('loader/show');
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
        window.addEventListener("message", this.receiveMessage, false);
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
    },
    computed:{
        ...mapGetters(["customer"]),
        lobbyTitle(){
            switch (this.lobbyState) {
                case this.LOBBY_STATE.LOGIN:
                    return "Hi Visitor";
                case this.LOBBY_STATE.SIGNUP:
                    return "Welcome onboard!";
                case this.LOBBY_STATE.THEME:
                    return "Theme";
                case this.LOBBY_STATE.BLOCK:
                    return "Block Intro";
                case this.LOBBY_STATE.CHAT:
                    return this.channel == "chat" ? "Chat" : "System";
                case this.LOBBY_STATE.ACCOUNT:
                    return "Account info"
            }
        },
        login(){
            return true;
        },
        // 藉由鍵盤事件判斷角色的移動狀態
        moveForward() {
            return this.keyInputs.includes('w') || this.keyInputs.includes('W') || this.keyInputs.includes('ArrowUp');
        },
        moveBackward() {
            return this.keyInputs.includes('s') || this.keyInputs.includes('S') || this.keyInputs.includes('ArrowDown');
        },
        moveLeft() {
            return this.keyInputs.includes('a') || this.keyInputs.includes('A');
        },
        moveRight() {
            return this.keyInputs.includes('d') || this.keyInputs.includes('D');
        },
        rotateLeft() {
            return this.keyInputs.includes('q') || this.keyInputs.includes('Q') || this.keyInputs.includes('ArrowLeft');
        },
        rotateRight() {
            return this.keyInputs.includes('e') || this.keyInputs.includes('E') || this.keyInputs.includes('ArrowRight');
        },
        lookup() {
            return this.keyInputs.includes('[');
        },
        lookdown() {
            return this.keyInputs.includes(']');
        },
    },
    methods:{
        ...mapActions(['setLogin','setLogout','setVendor']),
        init(){ 
            // this.loading = true;
            canvas = document.getElementById('main-scene');
            engine = new Babylon.Engine(canvas, true,{
                preserveDrawingBuffer: true,
                stencil: true,
                doNotHandleContextLost: true,
                disableWebGL2Support: false,
            }, true);
            this.setScene();
            this.setEngine(scene, engine);
            this.setCamera();
            // this.setLight();
            this.setLayout();
            this.setExhibitionTrackData();//initialize trackData
            this.setSockets();
            this.setCharacter();
            this.setAccount();
        },
        emitKeepMoveCamera(key) {
            this.keyInputs = [key];
        },
        emitResetKeyboard() {
            this.keyInputs = [];
        },
        setScene(){
            const vm = this;
            scene = new Babylon.Scene(engine);
            scene.clearColor = new Babylon.Color3.FromHexString("#ade8f4");
            scene.executeWhenReady(function () {
                vm.loading = false;
                engine?.runRenderLoop(function () {
                    if(!scene||!canvas) return;
                    scene.render();
                    // canvas.focus();
                });
            });
            scene.onPointerObservable.add((p)=>{
                if(p.type===Babylon.PointerEventTypes.POINTERTAP){
                    const m = p?.pickInfo?.pickedMesh || null;
                    if(!m) return;
                    const parent = m?._parentNode || null;
                    if(parent.name.includes('Shop')||parent.name.includes('Ext')){
                        let index = this.getOutermostParent(m).uniqueId;
                        this.attachedExhibition = this.exhibitionsData[index];
                        this.switchLobbyState(this.LOBBY_STATE.BLOCK);
                    }
                    if(m.name.includes('H')&&!m.name.includes('LogoHere')&&!m.name.includes('Door')){
                        let index = parseInt(m.name.split("_")[1],10)
                        this.attachedExhibition = this.exhibitionsData[index-1];
                        this.switchLobbyState(this.LOBBY_STATE.BLOCK);
                    }
                    if(m.name.includes('Floor')){
                        if(!this.character) return;
                        const pickedPoint = p.pickInfo?.pickedPoint.clone();
                        const characterCurrentPos = character.position.clone();
                        this.characterDestination.distance = Babylon.Vector3.Distance(pickedPoint,characterCurrentPos);
                        this.characterDestination.target = Babylon.Vector3.Normalize(pickedPoint.subtract(characterCurrentPos));
                    }
                }
                else if(p.tpye===Babylon.PointerEventTypes.POINTERWHEEL){
                    this.orthographicScaleY += p.event.deltaY*0.05;
                }
            })
            scene.registerBeforeRender(()=>{
                const moveSpeed = 0.25;
                if(this.move.x||this.move.y){
                    this.character.moveWithCollisions(vm.character.forward.scaleInPlace(this.move.y*-1*moveSpeed));
                    this.character.moveWithCollisions(camera.getDirection(new Babylon.Vector3(this.move.x*moveSpeed, 0, 0)));
                }
                if(this.characterDestination.distance > 0){
                    if(!this.character) return;
                    this.characterDestination.distance -= moveSpeed;
                    this.character.translate(this.characterDestination.target, moveSpeed, Babylon.Space.WORLD);
                }
            });
            scene.actionManager = new Babylon.ActionManager(scene);
            scene.actionManager.registerAction(new Babylon.ExecuteCodeAction(Babylon.ActionManager.OnKeyDownTrigger, function (evt) {
                //vm.keyInputs[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
                if (vm.keyInputs.indexOf(evt.sourceEvent.key) !== -1) return;
                vm.keyInputs.push(evt.sourceEvent.key);
            }));
            scene.actionManager.registerAction(new Babylon.ExecuteCodeAction(Babylon.ActionManager.OnKeyUpTrigger, function (evt) {
                //vm.keyInputs[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
                vm.keyInputs.shift(evt.sourceEvent.key)
                vm.move.x = vm.move.y = 0;
            }));
            scene.freezeMaterials();
            scene.shadowsEnabled = false;
            scene.renderTargetsEnabled = false;
            window.scene = scene;
        },
        throttle(callback, delay) {
            let time = new Date().getTime();
            if ((time - previousCall) >= delay || !previousCall) {
                previousCall = time;
                callback.apply(null, arguments);
            }
        },
        getOutermostParent(mesh){
            if(mesh._parentNode===null) return mesh;
            let parent = mesh;
            while(parent._parentNode!==null){
                parent = parent._parentNode;
            }
            return parent;
        },
        setCamera(){
            camera = new Babylon.ArcRotateCamera('playerCamera',Math.PI/2,Math.PI/3, 50, this?.character||new Babylon.Vector3.Zero(),scene, true);
            //camera = new Babylon.TargetCamera('playerCamera',new Babylon.Vector3(5.1525, 5.4030, 8.4147),scene, true);
            camera.lowerRadiusLimit = 5;
            camera.upperRadiusLimit = 10;
            camera.lowerBetaLimit = 0.7;
            camera.upperBetaLimit = 1.3;
            camera.wheelDeltaPercentage = 0.01;
            camera.angularSensibilityX = 2000;
            camera.angularSensibilityY = 2000;
            camera.attachControl(canvas,true);
        },
        setCharacter(){
            const vm = this;
            Babylon.SceneLoader.ImportMeshAsync('', 'static/models/', 'avatar.glb',scene)
            .then((container)=>{
                container.meshes[0].scaling.scaleInPlace(0.5);
                container.meshes[0].billboardMode = Babylon.Mesh.BILLBOARDMODE_Y;
                this.character = container.meshes[0];
                camera.lockedTarget = this.character.position;
                window.character = this.character;

            }).catch((err)=>{
                console.log('Err while character model loading');
            })
            scene.onBeforeRenderObservable.add(() => {
                let keydown = false
                const moveSpeed = 0.25;
                const rotateSpeed = 0.03;
                const rotateRatio = !vm.invertRotation ? 0.4 : 0.4;
                camera.setTarget(vm.character.position);
                if (vm.keyInputs.length == 0){
                    vm.move.x = vm.move.y = 0;
                    return
                };
                if (vm.moveForward) {
                    this.characterDestination.distance = 0;
                    vm.move.y = -moveSpeed;
                    keydown = true;
                }
                if (vm.moveBackward) {
                    this.characterDestination.distance = 0;
                    vm.move.y = moveSpeed;
                    keydown = true;
                }
                if (vm.moveLeft) {
                    this.characterDestination.distance = 0;
                    // vm.character.rotate(Babylon.Vector3.Up(), -rotateSpeed);
                    vm.move.x = -moveSpeed;
                    // camera.alpha += rotateSpeed;
                    keydown = true;
                }
                if (vm.moveRight) {
                    this.characterDestination.distance = 0;
                    vm.move.x = moveSpeed;
                    keydown = true;
                }
                if (vm.rotateLeft) {
                    camera.alpha += 0.02 * (vm.invertRotation ? rotateRatio : -rotateRatio);
                }
                if (vm.rotateRight) {
                    camera.alpha -= 0.02 * (vm.invertRotation ? rotateRatio : -rotateRatio);
                }
                if(vm.lookup){
                    camera.beta -= camera.beta > 0.7 ? 0.05 : 0;
                }
                if(vm.lookdown){
                    camera.beta += camera.beta < 1.3 ? 0.05 : 0;
                }
            });
        },
        syncMultiplePlayersData(){
            this.multiplePlayersData = this.multiplePlayersData.filter(e=>e!==null);
        },
        lookAt(obj, lookAtPosition) {
            lookAtPosition = lookAtPosition.subtract(obj.position);
            obj.rotation.y = -Math.atan2(lookAtPosition.z, lookAtPosition.x) - Math.PI/2;
        },
        setLight(){
            let hemLight = new Babylon.HemisphericLight("hemLight", new Babylon.Vector3(0, 1, 0),scene);
            hemLight.intensity = 0.5;
            hemLight.specular = Babylon.Color3.White();
        },
        setEngine(){
            resizer = window.addEventListener("resize", function (){
                engine.resize();
            });
            setLoadingScreen();
            function setLoadingScreen(){
                function customLoadingScreen() {}
                customLoadingScreen.prototype.displayLoadingUI = function () {};
                customLoadingScreen.prototype.hideLoadingUI = function () {
                    // this.loading = false;
                };
                const loadingScreen = new customLoadingScreen();
                engine.loadingScreen = loadingScreen;
            }
        },
        loadShopModel(){
            Babylon.SceneLoader.Append('static/models/', 'expo3.glb',scene, (container)=>{
                let thisMesh =scene.getMeshByName('__root__');
                thisMesh.name = 'ShopOriginal';
                thisMesh.position.set(30,9999,30);
                this.shopClone = thisMesh;
                this.setLayout();
            });
        },
        setLayout(){
            Babylon.SceneLoader.AppendAsync('static/models/', 'Lobby2-v3_renameHouse.glb', scene)
            .then((aScene)=>{
                this.getExhibitionsData(1, '', '', ()=>{
                });
                this.finishLoading();
            })
            .catch(err=>{
                console.log('Something went wrong!!!');
            })
        },
        setShopMatrix(size){
            let dirX = [0,1,0,-1], dirY = [1,0,-1,0];
            let index = 0, x = 0, y = -1, temp_x, temp_y;
            this.shopArr = Array(size).fill().map(() => Array(size).fill(0));
            this.shopSize = size;
            for (let i = 0; i < size * size; i++)
            {
                temp_x = x + dirX[index];
                temp_y = y + dirY[index];
                if (temp_x < 0 || temp_x > size - 1 || temp_y < 0 || temp_y > size - 1 || this.shopArr[temp_x][temp_y] != 0)
                {
                    index = (index + 1) % (size-1);
                }
                x += dirX[index];
                y += dirY[index];
                let sizeDouble = size*size;
                // this.shopArr[x][y] = (i + 1);//由1 ~ size*size排序
                this.shopArr[x][y] = sizeDouble-(i+1)+1;//由size*size ~ 1排序
            }
            this.setShop(size);
        },
        setShop(size){
            for(let j=0;j<size;j++){
                for(let k=0;k<size;k++){
                    let checkId = this.shopArr[j][k]-1;
                    let shop = this.shopClone.clone();

                    //Note : here's a simple and rough condition to set shops
                    //new version start
                    let logoPlane = shop._children.filter(e=>e.name=='.LogoHere');
                    let randMat = new Babylon.StandardMaterial('randMats',scene);
                    randMat.emissiveColor = Babylon.Color3.Random();
                    shop.scaling.scaleInPlace(3);
                    shop.uniqueId = checkId;
                    shop.name = 'Shop';
                    shop.tag = 'Shops';
                    shop.id = shop.name+shop.uniqueId-1;
                    // logoPlane[0].material = randMat;
                    Babylon.Animation.CreateAndStartAnimation('floatUp', shop, 'position.y', 1, 0.9, shop.position.y, 0.3/*end point*/, 2, new Babylon.SineEase(),()=>{});
                    
                    //1 - 鄰排交錯, 2 - 鄰間交錯
                    const matrixType = 2;
                    if(matrixType===1){
                        if(j%2===0){
                            shop.position.set(110-47*j,-13,95-44*k+25);
                            // logoPlane[0].material.emissiveColor = Babylon.Color3.Blue();
                        }
                        else{
                            shop.position.set(110-47*j,-13,95-49*k);
                            // logoPlane[0].material.emissiveColor = Babylon.Color3.Red();
                        }
                    }
                    else if(matrixType===2){
                        shop.position.set(110-60*j + ((k%2===0)?25:0),-13,95-60*k);
                        // logoPlane[0].material.emissiveColor = checkId%2===0?Babylon.Color3.Magenta():Babylon.Color3.Yellow();
                    }
                    else if(matrixType===3){
                        shop.position.set(110-47*j,-13,95-47*k);
                    }
                    //new version end
                }
            };
            this.setCharacter();
        },
        switchLobbyState(state){
            
            if(this.lobbyState===state&&this.lobbyMenuToggle&&this.lobbyState!==this.LOBBY_STATE.BLOCK){
                this.lobbyMenuToggle = false;
                return;
            }
            switch (state) {
                case this.LOBBY_STATE.LOGIN:
                    break;
                case this.LOBBY_STATE.SIGNUP:
                    break;
                case this.LOBBY_STATE.THEME:
                    break;
                case this.LOBBY_STATE.BLOCK:
                    break;
                case this.LOBBY_STATE.CHAT:
                    break;
            }
            this.extendMenu = false;
            this.lobbyState = state;
            this.lobbyMenuToggle = true;
        },
        setExhibitionTrackData(exhibitionId){
            if(localStorage.hasOwnProperty('exhibitionTrackData')){
                if(!exhibitionId) return;
                let trackIds = JSON.parse(localStorage.getItem('exhibitionTrackData')).ids;
                trackIds.push(exhibitionId);
                localStorage.setItem('exhibitionTrackData', JSON.stringify({ids: trackIds}));
            }
            else{
                localStorage.setItem('exhibitionTrackData', JSON.stringify({ids: []}));
            }
        },
        setSockets(){
            const vm = this;
            // Chatroom
            console.log(SEND_MESSAGE)
            this.socket.on(SEND_MESSAGE, (data)=>{
                console.log(data);
                if(data.name != this.nickname){
                         this.updateChatMessage({
                    name: data.name,
                    messages: data.messages,
                    theme: data.theme,
                }, true)
                }
            });
            // Multiplayer
        },
        getCharacterData(){
            const character = this?.character||null;
            if(!character) return null;
            return {
                avatar: 1,
                userId: this.nickname,
                name: this.nickname,
                theme: this.currentTheme,
                position: {
                    x: character.position.x,
                    y: character.position.y,
                    z: character.position.z,
                },
                rotation: {
                    x: character.rotation.x,
                    y: character.rotation.y,
                    z: character.rotation.z,
                },
                scaling:{
                    x: character.scaling.x,
                    y: character.scaling.y,
                    z: character.scaling.z,
                }
            }
        },
        keydownEvent(e){
            if(e.keyCode===13){
                if(!this.chatMessage.length) return;
                this.sendChatMessage();
            }
        },
        sendChatMessage(){
            this.updateChatMessage({
                name: this.nickname,
                messages: this.chatMessage,
                theme: this.currentTheme,
            });
        },
        confirmLogout(){
            if(this.login){
                this.setLogout();
                this.$message({
                    showClose: true,
                    message: `Logout`,
                    type: 'success',
                    duration: 1500,
                    onClose:()=>{
                        this.$store.dispatch('loader/show');
                        //window.location.href = location.protocol+'//'+window.location.host+'/landing';
                        this.$router.push("/landing");
                    }
                });
            }
        },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
        receiveMessage(event) {
            if (event.origin !== "https://blockchain-asset-platform.dlll.nccu.edu.tw") {
                return;
            }
            console.log("Received message from " + event.origin + ": " + event.data);
            if (event.data === 'logout') {
                // setCookie(token, "", -1);
                this.confirmLogout();
                document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.dlll.nccu.edu.tw; path=/; SameSite=None; Secure`;
            }
        },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
        goLanding(){
            window.location.href = location.protocol+'//'+window.location.host+'/landing';
        },
        async updateChatMessage(data={}, receiver=false){
            if(!data) return;
            if(data.theme!==this.currentTheme) return;
            let now = new Date();
            let month = (now.getMonth()+1).length>1?(now.getMonth()+1):'0'+(now.getMonth()+1);
            let day = (now.getDate().length>1)?(now.getDate()):now.getDate();
            let time = now.getHours() + ":" + now.getMinutes();
            let date = now.getFullYear()+'/'+month+'/'+day+' '+time;
            if(data.name=="admin"){
                await this.systemData.push({
                    date: date,
                    name: "展場公告",
                    messages: data.messages,
                });
            }else{
                await this.chatroomData.push({
                    date: date,
                    name: data.name,
                    messages: data.messages,
                });
            }

            if(!receiver){
                data.theme = this.currentTheme;//SET THEME
                console.log(SEND_MESSAGE)
                this.socket.emit(SEND_MESSAGE, data);
                this.chatMessage = '';
            }
            const chats = document.getElementsByClassName('chat-container');
            const lastChat = chats[chats.length-1]||null;
            if(lastChat){
                chats[chats.length-1].scrollIntoView({behavior: 'smooth'});
            }
        },
        enterExhibition(favOrHisEid=''){
            this.setExhibitionTrackData(favOrHisEid?favOrHisEid:this.attachedExhibition._id);
            this.$router.push({name: 'Exhibitions', query: {
                id: favOrHisEid?favOrHisEid:this.attachedExhibition._id,
                room:1
            }});
        },
        switchTheme(id){
            this.themeAnimatorText = `You are now in Theme ${id+1} - ${this.themeData[id].name}`;
            this.currentTheme = id+1;
            localStorage.setItem("currentTheme",this.currentTheme);
            this.currentLocationName = this.themeData[id].name;
            if(this.themeAnimator){
                clearTimeout(this.themeAnimator);
            }
            this.themeAnimator = setTimeout(()=>{this.themeAnimator = false}, 1500);
            this.chatroomData = [];
            this.getExhibitionsData(this.currentRegion);
        },
        async switchRegion(type){
            if((this.currentRegion < 2 && type==0)){
                this.$message({
                    showClose: true,
                    message: `You're in the first region now.`,
                    type: 'error',
                    duration: 1500,
                });
                return;
            }
            else if((this.currentRegion > this.exhibitionsPage.totalPages-1 && type==1)){
                this.$message({
                    showClose: true,
                    message: `You're in the last region now.`,
                    type: 'error',
                    duration: 1500,
                });
                return;
            }
            else if(this.switching){
                this.$message({
                    showClose: true,
                    message: `Switching region, please wait a moment...`,
                    type: 'error',
                    duration: 1500,
                });
                return;
            }
            this.currentRegion = (type===0)?this.currentRegion-1:this.currentRegion+1;
            this.switching = true;
            await this.getExhibitionsData(this.currentRegion, this.floors.key, this.tags.keyList);
            let perFrame = 1;
            let seconds = perFrame * 1.2;
            let meshes = scene.meshes.filter(data=>data.name===('Shop'));
            let lastIndex = this.currentRegion===this.exhibitionsPage.totalPages?this.exhibitionsPage.total:this.currentRegion*this.exhibitionsPage.pageSize;
            for(let i=0;i<meshes.length;i++){
                Babylon.Animation.CreateAndStartAnimation('floatDown', meshes[i], 'position.y', perFrame, seconds, meshes[i].position.y, -25, 2, new Babylon.SineEase(),()=>{
                    meshes[i].dispose();
                    if(i === meshes.length-1){
                        this.setShopMatrix(5);
                        this.switching = false;
                    }
                });
            }
            this.$message({
                showClose: true,
                message: `You are now in ${this.currentLocationName} NO.${(this.currentRegion-1)*(this.exhibitionsPage.pageSize)+1} - NO.${lastIndex}`,
                type: 'success',
                duration: 1500,
            });
        },
        switchFloor(floor){
            this.floors = floor;
            this.regenScene(floor,'');
        },
        switchCategory(tags){
            this.tags = tags,
            this.regenScene('', tags);
        },
        regenScene(floorData={},tagKey='', defaultName='All Exhibitions'){
            this.clearUI();
            this.currentLocationName = floorData.index ? (`${floorData.index}F `+this.$refs.floorUI.getCurrentFloor().name): tagKey ? ('Tags: '+tagKey.keyNameList) : defaultName;
            this.currentRegion = 1;
            engine.stopRenderLoop();
            engine.dispose();
            this.getExhibitionsData(1,floorData.key,tagKey.keyList,()=>{
                this.init();
            });
        },
        enterSpecificExhibition(eid){
            this.enterExhibition(eid);
            this.clearUI();
        },
        clearUI(){
            this.showExhibInfo = false;
            this.lastFunctionUI(false);
            this.$refs.hintBar.darkModeToggle(false);
        },
        setLocationName(name){
            this.currentLocationName = name;
        },
        async checkInfo(){
            let vm = this;
            let loginRes;
            await getUserData().then(res=>{
                loginRes = res.data.data;
                if(loginRes.type=='seller')vm.getVenderProfileInfo();
            }).catch(err=>{
                console.log(err);
                this.toggleHint(1500);
            });
            await this.setLogin(loginRes)
            .then(auth=>{
                //this.signupInit();
                //this.loginStatus = true;
                this.nickname = loginRes.nickname;
                this.setAccount();
            })
            .catch(err=>{
            })
        },
        async getVenderProfileInfo(){
            let vm = this
            let res = await getVenderProfile().catch(function (error) {
                  if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    return error.response.status
                  }
            });
            this.setVendor(res.data.data)
        },
        toggleHint(ms){
            this.hint = true;
            if(!ms) return;
            if(this.hintTimer){ 
                clearTimeout(this.hintTimer);
            }
            this.hintTimer = setTimeout(()=>{this.hint = false;}, ms);
        },
        async loginAccount(){
            let loginRes;
            await login(this.userForm).then(res=>{
                loginRes = res.data.data;
            }).catch(err=>{
                console.log(err);
            });
            await this.setLogin(loginRes).then(auth=>{
                if(auth==='normal'){
                    console.log('YES you just logged in.');
                }
            })
        },
        logoutDialog(){
            if(this.login){
                this.$refs.dialogLogout.active(); 
            }else{
                let routeData = this.$router.resolve({name: 'LandingPage'});
                window.open(routeData.href, '_blank');
            }
        },
        async getThemeData(callback=function (){}){
            await getExhibitionsFacets().then((res)=>{
                this.themeData = res?.data?.data?.themes;
            }).catch((err)=>{
            })
            callback();
        },
        async getExhibitionsData(page, floorKey='', tagKey='', callback=function (){}){
            await getExhibitions({params: {
                page: page,
                floors: floorKey,
                tags: tagKey,//like '1,2,3,4,5,6,7,8,9'
                theme:this.themeData[this.currentTheme-1].key
            }}).then((res)=>{
                this.prevExhibitionData = this.exhibitionsData;
                this.exhibitionsData = res.data.data.list;
                this.exhibitionsPage = res.data.data.pagination;
                this.attachedExhibition = this.exhibitionsData[0];
                this.setExhibitionLayout();
            }).catch((err)=>{
            })
            callback();
        },
        setExhibitionLayout(){
            let vm = this;
            if(scene.meshes){
                if(this.prevExhibitionData.length != 0 ){
                    this.prevExhibitionData.forEach((e,index)=>{
                            let idx = index+1<10 ? `0${index+1}`: index+1
                            let parent = scene.getMeshByName(`H_${idx}`);
                            parent._children.forEach((mesh)=>{
                                if(mesh.name.split("_")[1]=="LogoHere"){
                                    mesh.material = scene.getMaterialByName('LogoHere');
                                }
                            })
                })
                }
                if(this.exhibitionsData.length != 0){
                    this.exhibitionsData.forEach((e,index)=>{
                        let idx = index+1<10 ? `0${index+1}`: index+1
                        let parent = scene.getMeshByName(`H_${idx}`);
                        console.log(parent._position);
                        parent.dispose()
                        // Babylon.SceneLoader.ImportMeshAsync('', 'static/models/', 'avatar.glb',scene).then((result) => {
                        //     result.meshes[0].position.x= c_x;
                        //     result.meshes[0].position.y= c_y;
                        //     result.meshes[0].position.z= c_z;

                        // })
                        // function (newMeshes) {
                        //     console.log(newMeshes.meshes[0])
                        //     //newMeshes.position.x = -0.8485281467437744;
                        //     //newMeshes.position.y = -4.4014716148376465;
                        //     //newMeshes.position.z = 0;
                        // })
                        parent._children.forEach((mesh)=>{
                            //mesh = Babylon.SceneLoader.ImportMeshAsync('', 'static/models/', 'avatar.glb',scene)
                            if(mesh.name.split("_")[1]=="LogoHere"){
                                let texture = new Image();
                                texture.crossOrigin = "*";
                                texture.src = this.exhibitionsData[index].signboardUrl+`?${Math.floor(Math.random()*999)}`;
                                let myMaterial = new Babylon.StandardMaterial("myMaterial",scene);
                                myMaterial.diffuseTexture = new Babylon.Texture(texture.src, scene);
                                myMaterial.diffuseTexture.hasAlpha = true;
                                myMaterial.emissiveColor = new Babylon.Color3.White();
                                //myMaterial.emissiveTexture.vScale = -1;
                                myMaterial.diffuseTexture.vScale = -1;
                                mesh.material = myMaterial;
                            }
                        })
                    });
                }
                this.$store.dispatch('loader/hide');
            }
        },
        setAccount(){
            this.accountInform = this.customer
        },
        closeTip(){
            this.tips = false;
            localStorage.setItem("hasUsedLobby",true)
        },
        shotPhoto(){
            let vm = this;
            this.extendMenu = false;
            Babylon.Tools.CreateScreenshot(engine, camera, { width: window.innerWidth, height: window.innerHeight }, function (data) {
                vm.screenShotSrc = data;
            });
        },
        closeScreenShot(){
            this.screenShotSrc = "";
        },
        finishLoading(){
            if(localStorage.hasOwnProperty("currentTheme")){
                this.currentTheme = localStorage.getItem("currentTheme");
                let idx = parseInt(this.currentTheme);
                this.switchTheme(idx-1);
            }
        }
    },
    async mounted(){
        await this.getThemeData(()=>{
            this.currentLocationName = this.themeData[0].name;
        });
        this.init();
        if(localStorage.hasOwnProperty('token')){
            this.checkInfo();
        }
        if(!localStorage.hasOwnProperty("hasUsedLobby"))this.tips = true;
        this.$nextTick(() => {
            this.keyInputs = [];
        })
    }, 
    destroyed(){
        if(engine){
            engine.stopRenderLoop();
            engine.dispose();
        }
        resizer = null;
    },
}
</script>
<style lang='scss'>
@import '@/assets/scss/user_menu.scss';
@import '@/assets/scss/entrance_v2.scss';
@import '@/assets/scss/scrollbar.scss';
</style>